body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#basic-nav-dropdown {
  position: relative; /* Make the parent container relative */
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  height: 80px; /* Not sure if you want this height, adjust as needed */
  border-bottom: 2px solid transparent;
  border-radius: 2px;
}

.dropdown-menu {
  position: absolute;
  top: 100%; /* Position the dropdown menu below the parent */
  left: 0;
  z-index: 1000; /* Ensure it's on top of other elements */
  padding: 0.5rem 0; /* Optional: Adjust padding as needed */
  margin: 0; /* Optional: Remove default margin */
  list-style: none; /* Optional: Remove default list style */
  background-color: #333;
  border: 2px solid #ccc;
  border-radius: 4px;
}

.dropdown-item {
  color: #fff;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

@media screen and (max-width: 991px) {
  .dropdown-item{
    margin-top:5%;
  }
}
